@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global paragraph styling */
.paragraph {
  font-size: 1rem; /* Default font size for small screens */
  word-wrap: break-word; /* Prevents text overflow in narrow screens */
}

/* Responsiveness: Adjust font size for medium screens */
@media (min-width: 640px) {
  .paragraph {
    font-size: 1.125rem; /* Larger text for medium screens */
    line-height: 1.8;
  }
}

/* Responsiveness: Adjust font size for large screens */
@media (min-width: 1024px) {
  .paragraph {
    font-size: 1.2rem; /* Larger text for large screens */
    line-height: 1.5;
  }
}

.bg-ourcommitment {
  background-image: url("../public/images/landing/ourservices.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bg-ourcommitment::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  opacity: 0.6;
}


/* bg our products landing page */
.bg-ourproducts {
  background-image: url("../public/images/landing/ourproductsbg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bg-ourproducts::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  opacity: 0.6;
}
